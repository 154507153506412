@use 'sass:map';
@use '../../base' as *;
@use '../../themes/schemas' as *;

////
/// @group themes
/// @access public
/// @author <a href="https://github.com/simeonoff" target="_blank">Simeon Simeonoff</a>
/// @author <a href="https://github.com/desig9stein" target="_blank">Marin Popov</a>
////

/// @deprecated Use the `css-vars` mixin instead.
/// @see {mixin} css-vars
/// @param {Map} $theme - The calendar theme used to style the component.
@mixin date-picker($theme) {
    $variant: map.get($theme, '_meta', 'variant');
    $bootstrap-theme: $variant == 'bootstrap';
    $border-shadow: 0 0 0 rem(1px) var-get($theme, 'border-color');

    $action-area-height: map.get((
        material: (rem(40px), rem(46px), rem(52px)),
        fluent: (rem(40px), rem(48px), rem(54px)),
        bootstrap: (rem(47px), rem(54px), rem(64px)),
        indigo-design: (rem(40px), rem(44px), rem(48px)),
    ), $variant);

    %date-picker {
        // TODO move the shadow in the schemas
        box-shadow: $border-shadow, elevation(24),;
        border-radius: var-get($theme, 'border-radius');
        background: var-get($theme, 'content-background');
        overflow: hidden;

        igx-calendar,
        %days-view,
        %months-view,
        %years-view {
            box-shadow: none;
            border-radius: 0;
        }

        igx-divider {
            --color: #{var-get($theme, 'actions-divider-color')};
        }
    }

    %date-picker--dropdown {
        display: flex;
        flex: 1 0 0;
        flex-direction: column;
        box-shadow: $border-shadow, elevation(3);
    }

    %date-picker__buttons {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: rem(8px);
    }

    %date-picker__actions {
        @include sizable();

        --component-size: var(--ig-size, #{var-get($theme, 'default-size')});

        min-height: sizable($action-area-height...);
        display: flex;
        justify-content: flex-end;

        @if $variant == 'indigo-design' {
            padding: rem(8px) rem(16px);
        } @else {
            padding: rem(8px);
        }

        gap: rem(8px);

        // TODO remove this line after the override (--component-size: var(--ig-size, var(--ig-size-large)) is removed
        [igxButton] {
            --component-size: var(--ig-size, #{var-get($theme, 'default-size')}) !important;
        }
    }
}
