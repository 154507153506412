@use '../../../../utils/map' as *;
@use '../../../../typography/functions' as *;
@use '../light/combo' as *;

////
/// @package theming
/// @group schemas
/// @access public
////

/* stylelint-disable max-line-length */

/// Generates a dark material combo schema.
/// @type {Map}
/// @requires $material-combo
$dark-material-combo: $material-combo;

/// Generates a dark fluent combo schema.
/// @type {Map}
/// @requires $fluent-combo
$dark-fluent-combo: $fluent-combo;

/// Generates a dark bootstrap combo schema.
/// @type {Map}
/// @requires $bootstrap-combo
$dark-bootstrap-combo: $bootstrap-combo;

/// Generates a dark indigo combo schema.
/// @prop {Map} toggle-button-foreground [color: ('gray', 600)] - The combo toggle button foreground color.
/// @prop {Map} toggle-button-foreground-focus [color: ('gray', 600)] - The combo toggle button foreground color when the combo is focused.
/// @prop {Map} toggle-button-foreground-filled [color: ('gray', 600)] - The combo toggle button foreground color when the combo is focused.
/// @type {Map}
/// @requires $indigo-combo
$dark-indigo-combo: extend(
    $indigo-combo,
    (
        toggle-button-foreground: (
            color: (
                'gray',
                600,
            ),
        ),
        toggle-button-foreground-focus: (
            color: (
                'gray',
                600,
            ),
        ),
        toggle-button-foreground-filled: (
            color: (
                'gray',
                600,
            ),
        ),
    )
);
