@use 'sass:map';
@use '../../base' as *;
@use '../../themes/schemas' as *;
@use 'igniteui-theming/sass/animations/easings' as *;

////
/// @group themes
/// @access public
/// @author <a href="https://github.com/simeonoff" target="_blank">Simeon Simeonoff</a>
/// @author <a href="https://github.com/desig9stein" target="_blank">Marin Popov</a>
////

/// @param {Map} $schema [$light-material-schema] - The schema used as basis for styling the component.
/// @param {Map} $elevations [null] - The elevations (shadows) map to be used.
///
/// @param {Color} $background [null] - The navbar background color.
/// @param {Color} $text-color [null] - The navbar text color.
/// @param {box-shadow} $shadow [null] - The shadow of the navbar.
/// @param {Color} $idle-icon-color [null] - The navbar idle icon color.
/// @param {Color} $hover-icon-color [null] - The navbar hover icon color.
/// @param {Bool} $disable-shadow [true] - Sets the navbar shadow visibility.
/// @requires $light-material-schema
/// @example scss Change the background color
///   $my-navbar-theme: navbar-theme($background: green);
///   // Pass the theme to the css-vars() mixin
///   @include css-vars($my-navbar-theme);
@function navbar-theme(
    $schema: $light-material-schema,
    $elevations: null,

    $background: null,
    $text-color: null,
    $shadow: null,
    $idle-icon-color: null,
    $hover-icon-color: null,
    $disable-shadow: false
) {
    $name: 'igx-navbar';
    $navbar-schema: ();

    @if map.has-key($schema, 'navbar') {
        $navbar-schema: map.get($schema, 'navbar');
    } @else {
        $navbar-schema: $schema;
    }

    $theme: digest-schema($navbar-schema);
    $meta: map.get($theme, '_meta');

    @if not($text-color) and $background {
        $text-color: text-contrast($background);
    }

    @if not($idle-icon-color) and $background {
        $idle-icon-color: text-contrast($background);
    }

    @if not($hover-icon-color) and $background {
        $hover-icon-color: text-contrast($background);
    }

    @if not($shadow) {
        $elevation: map.get($navbar-schema, 'elevation');
        $shadow: elevation($elevation);
    }

    @if $disable-shadow {
        $shadow: none;
    }

    @return extend($theme, (
        name: $name,
        background: $background,
        text-color: $text-color,
        idle-icon-color: $idle-icon-color,
        hover-icon-color: $hover-icon-color,
        shadow: $shadow,
        _meta: map.merge(if($meta, $meta, ()), (
            variant: map.get($schema, '_meta', 'theme')
        )),
    ));
}

/// @deprecated Use the `css-vars` mixin instead.
/// @see {mixin} css-vars
/// @param {Map} $theme - The theme used to style the component.
@mixin navbar($theme) {
    @include css-vars($theme);
    $variant: map.get($theme, '_meta', 'variant');

    $navbar-padding: rem(16px);
    $navbar-title-fz: rem(18px, 16px);
    $navbar-title-lh: rem(18px, 16px);
    $navbar-title-margin: 0;

    %igx-navbar-display {
        display: flex;
        position: relative;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        min-height: rem(56px);
        max-height: rem(128px);
        padding-inline: $navbar-padding;
        background: var-get($theme, 'background');
        color: var-get($theme, 'text-color');
        box-shadow: var-get($theme, 'shadow');
        z-index: 4;
        overflow: hidden;

        @if $variant == 'bootstrap' {
            border-bottom: rem(1px) solid var-get($theme, 'border-color');
        }
    }

    %igx-navbar-part {
        display: flex;
        align-items: center;
    }

    %igx-navbar-title {
        @include line-clamp(4, true, true);
        margin: $navbar-title-margin;
        flex-grow: 1;
        user-select: text;
        display: flex;
        flex-direction: row;
    }

    %igx-navbar-bundle {
        @extend %igx-navbar-part;
        user-select: none;

        > * + * {
            margin-inline-start: rem(16px);
        }
    }

    %igx-navbar-icon-display,
    %igx-navbar-part {
        igx-icon,
        [igxIconButton] {
            --component-size: 3;

            cursor: pointer;
            color: var-get($theme, 'idle-icon-color');
            transition: color .15s $out-quad;

            &:hover {
                color: var-get($theme, 'hover-icon-color');
            }
        }
    }

    %igx-navbar-left {
        @extend %igx-navbar-bundle;
        @extend %igx-navbar-icon-display;
        flex-grow: 1;
    }

    %igx-navbar-right {
        @extend %igx-navbar-bundle;
        @extend %igx-navbar-icon-display;
    }

    igx-navbar-action,
    [igxNavbarAction] {
        @extend %igx-navbar-part;
    }

    igx-navbar-title,
    [igxNavbarTitle] {
        @extend %igx-navbar-part;
        @extend %igx-navbar-title !optional;
    }
}

/// Adds typography styles for the igx-navbar component.
/// Uses the 'body-1', 'caption'
/// category from the typographic scale.
/// @group typography
/// @param {Map} $categories [(title: 'h6')] - The categories from the typographic scale used for type styles.
@mixin navbar-typography($categories: (title: 'h6')) {
    $title: map.get($categories, 'title');

    %igx-navbar-title {
        @include type-style($title);
        margin-bottom: 0;
    }
}
