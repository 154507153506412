/* stylelint-disable max-line-length */
////
/// @package theming
/// @group schemas
/// @access public
/// @author <a href="https://github.com/simeonoff" target="_blank">Simeon Simeonoff</a>
////

/// Generates a material base doughnut chart schema.
/// @type {Map}
/// @property {List} brushes [series] - Defines the palette from which automatically assigned series colors are selected.
/// @property {List} outlines [series] - Defines the palette from which automatically assigned series outline colors are selected.
/// @property {Color} others-category-fill [null] - Sets the fill color for others category.
/// @property {Number} others-category-opacity [null] - Sets the opacity for others category.
/// @property {Color} others-category-stroke [null] - Sets the others category stroke color.
/// @property {Number} others-category-stroke-thickness [null] - Sets the others category stroke thickness.
/// @property {Color} selected-slice-fill [null] - Sets the fill color of the selected slice.
/// @property {Number} selected-slice-opacity [null] - Sets the opacity of the selected slice.
/// @property {Color} selected-slice-stroke [null] - Sets the stroke color of the selected slice.
/// @property {Number} selected-slice-stroke-thickness [null] - Sets the stroke thickness of the selected slice.
$material-doughnut-chart: (
    'brushes': series,
    'outlines': series,
    'others-category-fill': null,
    'others-category-opacity': null,
    'others-category-stroke': null,
    'others-category-stroke-thickness': null,
    'selected-slice-fill': null,
    'selected-slice-opacity': null,
    'selected-slice-stroke': null,
    'selected-slice-stroke-thickness': null,
);

/// Generates a fluent doughnut-chart schema.
/// @type {Map}
/// @requires {function} extend
/// @requires {Map} $material-doughnut-chart
$fluent-doughnut-chart: $material-doughnut-chart;

/// Generates a bootstrap doughnut-chart schema.
/// @type {Map}
/// @requires {function} extend
/// @requires {Map} $material-doughnut-chart
$bootstrap-doughnut-chart: $material-doughnut-chart;

/// Generates an indigo doughnut-chart schema.
/// @type {Map}
/// @requires {function} extend
/// @requires {Map} $material-doughnut-chart
$indigo-doughnut-chart: $material-doughnut-chart;
