@use '../../../../utils/map' as *;
@use '../../../../typography/functions' as *;
@use '../light/select' as *;

////
/// @package theming
/// @group schemas
/// @access public
////

/* stylelint-disable max-line-length */

/// Generates a dark material select schema.
/// @type {Map}
/// @requires $material-select
$dark-material-select: $material-select;

/// Generates a dark fluent select schema.
/// @type {Map}
/// @requires $fluent-select
$dark-fluent-select: $fluent-select;

/// Generates a dark bootstrap select schema.
/// @type {Map}
/// @requires $bootstrap-select
$dark-bootstrap-select: $bootstrap-select;

/// Generates a dark indigo select schema.
/// @type {Map}
/// @prop {Map} toggle-button-foreground [color: ('gray', 600)] - The select toggle button foreground color.
/// @prop {Map} toggle-button-foreground-focus [color: ('gray', 600)] - The select toggle button foreground color when the select is focused.
/// @prop {Map} toggle-button-foreground-filled [color: ('gray', 600)] - The select toggle button foreground color when the select is focused.
/// @requires $indigo-select
$dark-indigo-select: extend(
    $indigo-select,
    (
        toggle-button-foreground: (
            color: (
                'gray',
                600,
            ),
        ),
        toggle-button-foreground-focus: (
            color: (
                'gray',
                600,
            ),
        ),
        toggle-button-foreground-filled: (
            color: (
                'gray',
                600,
            ),
        ),
    )
);
