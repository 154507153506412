@use 'sass:map';
@use 'sass:meta';
@use '../../base' as *;
@use '../../themes/schemas' as *;

////
/// @group themes
/// @access public
/// @author <a href="https://github.com/simeonoff" target="_blank">Simeon Simeonoff</a>
////

/// @param {Map} $schema [$light-material-schema] - The schema used as basis for styling the component.
/// @param {Map} $color [null] - The text color.
@function label-theme(
    $schema: $light-material-schema,
    $color: null
) {
    $name: 'igx-label';
    $selector: '[igxLabel]';
    $label-schema: ();

    @if map.has-key($schema, 'label') {
        $label-schema: map.get($schema, 'label');
    } @else {
        $label-schema: $schema;
    }

    $theme: digest-schema($label-schema);

    @return extend($theme, (
        name: $name,
        selector: $selector,
        color: $color,
        variant: map.get($schema, '_meta', 'theme'),
    ));
}

/// @deprecated Use the `css-vars` mixin instead.
/// @see {mixin} css-vars
/// @param {Map} $theme - The theme used to style the component.
@mixin label($theme) {
    // The --variant CSS produced by css-vars is needed also
    // when dynamically switching between the input `type` attribute.
    @include css-vars($theme);
    $variant: map.get($theme, 'variant');

    %label-base {
        @include ellipsis();
        position: relative;
        color: var-get($theme, 'color');
        max-width: 100%;
        line-height: normal;
        font-size: rem(16px);

        [dir='rtl'] & {
            transform-origin: top right;
        }

        @if $variant == 'fluent' or $variant == 'indigo-design' {
            font-size: rem(14px);
            font-weight: 600;
            height: auto;
        }

        @if $variant == 'bootstrap' {
            line-height: rem(24px);
            height: auto;
        }
    }
}
