@use 'sass:map';
@use 'sass:meta';
@use '../../base' as *;
@use '../../themes/schemas' as *;
@use 'igniteui-theming/sass/animations/easings' as *;

////
/// @group themes
/// @access public
/// @author <a href="https://github.com/simeonoff" target="_blank">Simeon Simeonoff</a>
/// @author <a href="https://github.com/desig9stein" target="_blank">Marin Popov</a>
////

/// If only background color is specified,
/// the idle item color will be assigned automatically to a contrasting color.
/// @param {Map} $schema [$light-material-schema] - The schema used as basis for styling the component.
/// @param {Map} $elevations [null] - The elevations (shadows) map to be used.
/// @param {Color} $background [null] - The background color used for the toast.
/// @param {Color} $idle-item-color [null] - The text-color used for the idle color.
/// @param {Color} $active-item-color [null] - The text-color used for the active color.
/// @param {box-shadow} $shadow [null] - Sets a shadow to be used for the bar.
///
/// @requires $light-material-schema
///
/// @example scss Set a custom background color
///   $my-bottom-nav-theme: bottom-nav-theme($background: black);
///   // Pass the theme to the css-vars() mixin
///   @include css-vars($my-bottom-nav-theme);
@function bottom-nav-theme(
    $schema: $light-material-schema,
    $elevations: null,

    $background: null,
    $idle-item-color: null,
    $active-item-color: null,
    $shadow: null
) {
    $name: 'igx-bottom-nav';
    $bottom-nav-schema: ();

    @if map.has-key($schema, 'bottom-nav') {
        $bottom-nav-schema: map.get($schema, 'bottom-nav');
    } @else {
        $bottom-nav-schema: $schema;
    }

    $theme: digest-schema($bottom-nav-schema);

    @if not($idle-item-color) and $background {
        @if meta.type-of($background) == 'color' {
            $idle-item-color: rgba(text-contrast($background), .7);
        }
    }

    @if not($shadow) {
        $elevation: map.get($bottom-nav-schema, 'elevation');
        $shadow: elevation($elevation);
    }

    @return extend($theme, (
        name: $name,
        background: $background,
        idle-item-color: $idle-item-color,
        active-item-color: $active-item-color,
        shadow: $shadow
    ));
}

/// @deprecated Use the `css-vars` mixin instead.
/// @see {mixin} css-vars
/// @param {Map} $theme - The theme used to style the component.
@mixin bottom-nav($theme) {
    @include css-vars($theme);

    $menu-height: rem(56px);
    $item-min-width: rem(80px);
    $item-max-width: rem(168px);
    $item-padding: 0 rem(12px, 16px);

    %igx-bottom-nav-panel {
        display: block;

        &:focus {
            outline-style: none;
        }

        &:empty {
            display: none;
        }
    }

    %igx-bottom-nav-menu {
        display: flex;
        position: fixed;
        justify-content: center;
        align-items: center;
        inset-inline-start: 0;
        inset-inline-end: 0;
        height: $menu-height;
        background: var-get($theme, 'background');
        overflow: hidden;
        z-index: 8;
    }

    %igx-bottom-nav-menu--top {
        inset-block-start: 0;
        inset-block-end: inherit;
        box-shadow: var-get($theme, 'shadow');
    }

    %igx-bottom-nav-menu--bottom {
        inset-block-start: inherit;
        inset-block-end: 0;
        box-shadow: var-get($theme, 'shadow');
    }

    %igx-bottom-nav-menu-item {
        display: flex;
        position: relative;
        flex-flow: column nowrap;
        flex: 1;
        align-items: center;
        justify-content: center;
        min-width: $item-min-width;
        max-width: $item-max-width;
        height: 100%;
        color: var-get($theme, 'idle-item-color');
        cursor: pointer;
        user-select: none;
        overflow: hidden;
        padding: $item-padding;
        -webkit-tap-highlight-color: transparent;
        outline-style: none;

        igx-icon {
            --component-size: 3;
        }
    }

    %igx-bottom-nav-menu-item--disabled {
        opacity: .5;
        cursor: default;
        pointer-events: none;
    }

    %igx-bottom-nav-menu-item--selected {
        color: var-get($theme, 'active-item-color');
        transition: color .15s $in-out-quad, opacity .25s $in-out-quad;
    }

    %igx-tab-label {
        @include ellipsis();
        padding-block-start: rem(4px);
        max-width: 100%;
        text-align: center;
        transform: translateZ(0);
        transition: transform .15s $in-out-quad;
        z-index: 1;
    }

    %igx-tab-icon {
        display: flex;
        position: relative;
        justify-content: center;
        height: rem(24px);
        padding: 0 rem(8px);
        transform: translateZ(0);
        transition: transform .15s $in-out-quad;
        z-index: 1;
    }

    %igx-tab-icon--selected {
        transform: translateY(-2px);
    }

    %igx-tab-label--selected {
        transform: translateY(-2px) scale(1.166667);
    }
}

/// Adds typography styles for the igx-bottom-nav component.
/// Uses the 'caption'
/// category from the typographic scale.
/// @group typography
/// @param {Map} $categories [(label: 'caption')] - The categories from the typographic scale used for type styles.
@mixin bottom-nav-typography($categories: (label: 'caption')) {
    $label: map.get($categories, 'label');

    %igx-tab-label {
        @include type-style($label) {
            margin: 0
        }
    }
}
