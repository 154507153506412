////
/// @package theming
/// @group schemas
/// @access public
////

/* stylelint-disable max-line-length */

/// @type Map
/// @prop {Number} search-resting-elevation [1] - The elevation level, between 0-24, to be used for the resting state of the search input.
/// @prop {Number} search-hover-elevation [2] - The elevation level, between 0-24, to be used for the hover state of the search input.
/// @prop {Number} search-disabled-elevation [0] - The elevation level, between 0-24, to be used for the disabled state of the search input.
$default-elevation-input-group: (
    search-resting-elevation: 1,
    search-hover-elevation: 2,
    search-disabled-elevation: 0,
);

/// @type Map
/// @prop {Number} search-resting-elevation [0] - The elevation level, between 0-24, to be used for the resting state of the search input.
/// @prop {Number} search-hover-elevation [0] - The elevation level, between 0-24, to be used for the hover state of the search input.
/// @prop {Number} search-disabled-elevation [0] - The elevation level, between 0-24, to be used for the disabled state of the search input.
$fluent-elevation-input-group: (
    search-resting-elevation: 0,
    search-hover-elevation: 0,
    search-disabled-elevation: 0,
);

/// @type Map
/// @prop {Number} search-resting-elevation [0] - The elevation level, between 0-24, to be used for the resting state of the search input.
/// @prop {Number} search-hover-elevation [0] - The elevation level, between 0-24, to be used for the hover state of the search input.
/// @prop {Number} search-disabled-elevation [0] - The elevation level, between 0-24, to be used for the disabled state of the search input.
$indigo-elevation-input-group: (
    search-resting-elevation: 0,
    search-hover-elevation: 0,
    search-disabled-elevation: 0,
);
