@use '../../../../utils/map' as *;
@use '../../../../typography/functions' as *;
@use '../elevation/button' as *;

/* stylelint-disable max-line-length */

////
/// @package theming
/// @group schemas
/// @access public
////

/// @type {Map}
/// @prop {Color} shadow-color [transparent] - The shadow color of the button.
/// @prop {Color} border-color [transparent] - The outline color of the button.
/// @prop {Color} hover-border-color [transparent] - The hover border color of the button.
/// @prop {Color} focus-border-color [transparent] - The focus border color of the button.
/// @prop {Color} focus-visible-border-color [transparent] - The focus-visible border color of the button.
/// @prop {Color} active-border-color [transparent] - The active border color of the button.
/// @prop {Color} disabled-border-color [transparent] - The disabled border color of the button.
/// @prop {Map} disabled-background [color: ('gray', 300)] - The disabled background color of the button.
/// @prop {Map} disabled-foreground [color: ('gray', 500)] - The disabled foreground color of the button.
/// @prop {Map} disabled-icon-color [color: ('gray', 500)] - The disabled icon color of the button.
/// @prop {List} size [(rem(24px), rem(30px), rem(36px))] - The size used for the button.
/// @prop {Number} default-size [1] - The default size used for the button component.
$light-base-button: (
    shadow-color: transparent,
    border-color: transparent,
    hover-border-color: transparent,
    focus-border-color: transparent,
    focus-visible-border-color: transparent,
    active-border-color: transparent,
    disabled-border-color: transparent,
    disabled-background: (
        color: (
            'gray',
            300,
        ),
    ),
    disabled-foreground: (
        color: (
            'gray',
            500,
        ),
    ),
    disabled-icon-color: (
        color: (
            'gray',
            500,
        ),
    ),
    size: (
        sizable: (
            rem(24px),
            rem(30px),
            rem(36px),
        ),
    ),
    default-size: 1,
);

/// @type {Map}
/// @prop {Color} background [transparent] - The background color of a flat button.
/// @prop {Map} focus-foreground [color: ('secondary', 500)] - The text color of a flat button when focused with click and keyboard.
/// @prop {List} border-radius [(rem(4px), rem(0), rem(20px))] - The border radius used for flat button.
/// @requires {Map} $light-base-button
/// @requires {Map} $flat-elevation-button
$light-flat-button: extend(
    $light-base-button,
    $flat-elevation-button,
    (
        selector: '[igxButton="flat"], .igx-button--flat',
        background: transparent,
        focus-foreground: (
            color: (
                'secondary',
                500,
            ),
        ),
        border-radius: (
            border-radius: (
                rem(4px),
                rem(0),
                rem(20px),
            ),
        ),
    )
);

/// @type {Map}
/// @prop {Map} disabled-border-color [color: ('gray', 300)] - The disabled focused border color of an outlined button.
/// @requires {Map} $light-flat-button
$light-outlined-button: extend(
    $light-flat-button,
    (
        selector: '[igxButton="outlined"], .igx-button--outlined',
        disabled-border-color: (
            color: (
                'gray',
                300,
            ),
        ),
    )
);

/// @type {Map}
/// @prop {Map} background [color: ('secondary', 500)] - The background color of an contained button.
/// @prop {Map} active-background [color: ('secondary', 300)] - The active background color of a contained button.
/// @prop {Number} resting-elevation [2] - The elevation level, between 0-24, to be used for the resting state.
/// @prop {Number} hover-elevation [4] - The elevation level, between 0-24, to be used for the hover state.
/// @prop {Number} focus-elevation [8] - The elevation level, between 0-24, to be used for the focus state.
/// @prop {List} border-radius [(rem(4px), rem(0), rem(20px))] - The border radius used for contained button.
/// @requires {Map} $light-base-button
/// @requires {Map} $light-contained-elevation
$light-contained-button: extend(
    $light-base-button,
    $light-contained-elevation,
    (
        selector: '[igxButton="contained"], .igx-button--contained',
        background: (
            color: (
                'secondary',
                500,
            ),
        ),
        active-background: (
            color: (
                'secondary',
                300,
            ),
        ),
        border-radius: (
            border-radius: (
                rem(4px),
                rem(0),
                rem(20px),
            ),
        ),
    )
);

/// @type {Map}
/// @prop {Number} resting-elevation [6] - The elevation level, between 0-24, to be used for the resting state.
/// @prop {Number} hover-elevation [12] - The elevation level, between 0-24, to be used for the hover state.
/// @prop {Number} focus-elevation [12] - The elevation level, between 0-24, to be used for the focus state.
/// @prop {List} size [(rem(32px), rem(40px), rem(48px))] - The size used for floating button.
/// @requires {Map} $light-contained-button
/// @requires {Map} $light-fab-elevation
$light-fab-button: extend(
    $light-contained-button,
    $light-fab-elevation,
    (
        selector: '[igxButton="fab"], .igx-button--fab',
        size: (
            sizable: (
                rem(32px),
                rem(40px),
                rem(48px),
            ),
        ),
    )
);

/// Generates a light button schema.
/// @type {Map}
/// @requires {Map} $light-flat-button
/// @requires {Map} $light-outlined-button
/// @requires {Map} $light-contained-button
/// @requires {Map} $light-fab-button
$light-button: (
    flat: $light-flat-button,
    outlined: $light-outlined-button,
    contained: $light-contained-button,
    fab: $light-fab-button,
);

/// @type {Map}
/// @requires {Map} $light-base-button
$material-base-button: $light-base-button;

/// @type {Map}
/// @prop {Map} foreground [color: ('secondary', 500)] - The idle text color of a flat button.
/// @prop {Map} icon-color [color: ('secondary', 500)] - The idle icon color of the button.
/// @prop {Map} icon-color-hover [color: ('secondary', 500)] - The hover icon color of the button.
/// @prop {Map} hover-background [color: ('secondary', 500, .08)] - The hover background color of a flat button.
/// @prop {Map} hover-foreground [color: ('secondary', 500)] - The hover text color of a flat button.
/// @prop {Map} focus-background [color: ('secondary', 500, .32)] - The background color of a flat button when focused with click and keyboard.
/// @prop {Map} focus-foreground [color: ('secondary', 500)] - The text color of a flat button when focused with click and keyboard.
/// @prop {Map} focus-hover-background [color: ('secondary', 500, .24)] - The background color on focus hovered state of the button.
/// @prop {Map} focus-hover-foreground [color: ('secondary', 500)] - The text color on focus hovered state of the button.
/// @prop {Map} focus-visible-background [color: ('secondary', 500, .16)] - The focus-visible background color of a flat button.
/// @prop {Map} focus-visible-foreground [color: ('secondary', 500)] - The focus-visible text color of a flat button.
/// @prop {Map} active-background [color: ('secondary', 500, .16)] - The active background color of a flat button.
/// @prop {Map} active-foreground [color: ('secondary', 500)] - The active text color of a flat button.
/// @prop {Color} disabled-background [transparent] - The disabled background color of a flat button.
/// @requires {Map} $material-base-button
/// @requires {Map} $light-flat-button
$material-flat-button: extend(
    $material-base-button,
    $light-flat-button,
    (
        foreground: (
            color: (
                'secondary',
                500,
            ),
        ),
        icon-color: (
            color: (
                'secondary',
                500,
            ),
        ),
        icon-color-hover: (
            color: (
                'secondary',
                500,
            ),
        ),
        hover-background: (
            color: (
                'secondary',
                500,
                0.08,
            ),
        ),
        hover-foreground: (
            color: (
                'secondary',
                500,
            ),
        ),
        focus-background: (
            color: (
                'secondary',
                500,
                0.32,
            ),
        ),
        focus-visible-background: (
            color: (
                'secondary',
                500,
                0.16,
            ),
        ),
        focus-hover-background: (
            color: (
                'secondary',
                500,
                0.24,
            ),
        ),
        focus-hover-foreground: (
            color: (
                'secondary',
                500,
            ),
        ),
        focus-visible-foreground: (
            color: (
                'secondary',
                500,
            ),
        ),
        active-background: (
            color: (
                'secondary',
                500,
                0.16,
            ),
        ),
        active-foreground: (
            color: (
                'secondary',
                500,
            ),
        ),
        disabled-background: transparent,
    )
);

/// @type {Map}
/// @prop {Map} border-color [color: ('secondary', 500)] - The outline color of an outlined button.
/// @prop {Map} hover-border-color [color: ('secondary', 500)] - The hover border color of an outlined button.
/// @prop {Map} focus-border-color [color: ('secondary', 500)] - The focus border color of an outlined button.
/// @prop {Map} focus-visible-border-color [color: ('secondary', 500)] - The focus-visible border color of an outlined button.
/// @prop {Map} active-border-color [color: ('secondary', 500)] - The active border color of an outlined button.
/// @requires {Map} $material-base-button
/// @requires {Map} $light-outlined-button
$material-outlined-button: extend(
    $material-flat-button,
    $light-outlined-button,
    (
        border-color: (
            color: (
                'secondary',
                500,
            ),
        ),
        hover-border-color: (
            color: (
                'secondary',
                500,
            ),
        ),
        focus-border-color: (
            color: (
                'secondary',
                500,
            ),
        ),
        focus-visible-border-color: (
            color: (
                'secondary',
                500,
            ),
        ),
        active-border-color: (
            color: (
                'secondary',
                500,
            ),
        ),
        disabled-background: transparent,
    )
);

/// @type {Map}
/// @prop {Map} foreground [contrast-color: ('secondary', 400)] - The idle text color of a contained button.
/// @prop {Map} icon-color [contrast-color: ('secondary', 400)] - The idle icon color of the button.
/// @prop {Map} hover-background [color: ('secondary', 400)] - The hover background color of a contained button.
/// @prop {Map} hover-foreground [contrast-color: ('secondary', 400)] - The hover text color of a contained button.
/// @prop {Map} icon-color-hover [contrast-color: ('secondary', 400)] - The hover icon color of the button.
/// @prop {Map} focus-background [color: ('secondary', 300)] - The background color of a contained button when focused with click and keyboard.
/// @prop {Map} focus-foreground [contrast-color: ('secondary', 300)] - The text color of a contained button when focused with click and keyboard.
/// @prop {Map} focus-visible-background [color: ('secondary', 300)] - The focus-visible background color of a contained button.
/// @prop {Map} focus-visible-foreground [contrast-color: ('secondary', 300)] - The focus-visible text color of a contained button.
/// @prop {Map} focus-hover-background [color: ('secondary', 400)] - The background color on focus hovered state of the button.
/// @prop {Map} focus-hover-foreground [contrast-color: ('secondary', 400)] - The text color on focus hovered state of the button.
/// @prop {Map} active-foreground [contrast-color: ('secondary', 300)] - The active text color of a contained button.
/// @prop {Map} disabled-background [color: ('gray', 100)] - The disabled background color of the button.
/// @requires {Map} $light-contained-button
/// @requires {Map} $material-base-button
$material-contained-button: extend(
    $material-base-button,
    $light-contained-button,
    (
        foreground: (
            contrast-color: (
                'secondary',
                400,
            ),
        ),
        icon-color: (
            contrast-color: (
                'secondary',
                400,
            ),
        ),
        icon-color-hover: (
            contrast-color: (
                'secondary',
                400,
            ),
        ),
        hover-background: (
            color: (
                'secondary',
                400,
            ),
        ),
        hover-foreground: (
            contrast-color: (
                'secondary',
                400,
            ),
        ),
        focus-background: (
            color: (
                'secondary',
                300,
            ),
        ),
        focus-foreground: (
            contrast-color: (
                'secondary',
                300,
            ),
        ),
        focus-hover-background: (
            color: (
                'secondary',
                400,
            ),
        ),
        focus-hover-foreground: (
            contrast-color: (
                'secondary',
                400,
            ),
        ),
        focus-visible-background: (
            color: (
                'secondary',
                300,
            ),
        ),
        focus-visible-foreground: (
            contrast-color: (
                'secondary',
                300,
            ),
        ),
        active-foreground: (
            contrast-color: (
                'secondary',
                300,
            ),
        ),
        disabled-background: (
            color: (
                'gray',
                100,
            ),
        ),
    )
);

/// @type {Map}
/// @prop {Map} disabled-background [color: ('gray', 100)] - The disabled background color of the button.
/// @prop {List} border-radius [(rem(28px), rem(12px), rem(28px))] - The border radius used for floating button.
/// @requires {Map} $light-fab-button
/// @requires {Map} $material-base-button
$material-fab-button: extend(
    $material-base-button,
    $material-contained-button,
    $light-fab-button,
    (
        selector: '[igxButton="fab"], .igx-button--fab',
        disabled-background: (
            color: (
                'gray',
                100,
            ),
        ),
        border-radius: (
            border-radius: (
                rem(28px),
                rem(12px),
                rem(28px),
            ),
        ),
    )
);

/// Generates a light material button schema.
/// @type {Map}
/// @requires {Map} $material-flat-button
/// @requires {Map} $material-outlined-button
/// @requires {Map} $material-contained-button
/// @requires {Map} $material-fab-button
$material-button: (
    flat: $material-flat-button,
    outlined: $material-outlined-button,
    contained: $material-contained-button,
    fab: $material-fab-button,
);

/// @type {Map}
/// @requires {Map} $light-base-button
/// @requires {Map} $flat-elevation-button
/// @prop {Map} foreground [color: ('gray', 900)] - The idle text color of the button.
/// @prop {Map} icon-color [color: ('gray', 900)] - The idle icon color of the button.
/// @prop {Map} icon-color-hover [color: ('gray', 900)] - The hover icon color of the button.
/// @prop {Map} focus-visible-border-color [color: ('gray', 700)] - The focus-visible border color of the button.
/// @prop {Map} hover-background [color: ('gray', 200)] - The hover background color of the button.
/// @prop {Map} hover-foreground [color: ('gray', 900)] - The hover text color of the button.
/// @prop {Map} focus-background [color: ('gray', 300)] - The background color of the button when focused with click and keyboard.
/// @prop {Map} focus-foreground [color: ('gray', 900)] - The text color of the button when focused with click and keyboard.
/// @prop {Map} active-background [color: ('gray', 300)] - The active background color of the button.
/// @prop {Map} active-foreground [color: ('gray', 900)] - The active text color of the button.
/// @prop {Map} focus-hover-background [color: ('gray', 200)] - The background color on focus hovered state of the button.
/// @prop {Map} focus-hover-foreground [color: ('gray', 900)] - The focus-hover text color of the button.
/// @prop {Color} focus-visible-background [transparent] - The focus-visible text color of the button.
/// @prop {Map} disabled-background [color: ('gray', 100)] - The disabled background color of the button.
/// @prop {List} border-radius [(rem(2px), rem(0), rem(20px))] - The border radius used for the button.
/// @prop {List} size [(rem(24px), rem(32px), rem(38px))] - The size used for the button.
/// @prop {Number} default-size [2] - The default size used for the button component.
$fluent-base-button: extend(
    $light-base-button,
    $flat-elevation-button,
    (
        foreground: (
            color: (
                'gray',
                900,
            ),
        ),
        icon-color: (
            color: (
                'gray',
                900,
            ),
        ),
        icon-color-hover: (
            color: (
                'gray',
                900,
            ),
        ),
        focus-visible-border-color: (
            color: (
                'gray',
                700,
            ),
        ),
        hover-background: (
            color: (
                'gray',
                200,
            ),
        ),
        hover-foreground: (
            color: (
                'gray',
                900,
            ),
        ),
        focus-background: (
            color: (
                'gray',
                300,
            ),
        ),
        focus-foreground: (
            color: (
                'gray',
                900,
            ),
        ),
        active-background: (
            color: (
                'gray',
                300,
            ),
        ),
        active-foreground: (
            color: (
                'gray',
                900,
            ),
        ),
        focus-hover-background: (
            color: (
                'gray',
                200,
            ),
        ),
        focus-hover-foreground: (
            color: (
                'gray',
                900,
            ),
        ),
        focus-visible-foreground: (
            color: (
                'gray',
                900,
            ),
        ),
        focus-visible-background: transparent,
        disabled-background: (
            color: (
                'gray',
                100,
            ),
        ),
        border-radius: (
            border-radius: (
                rem(2px),
                rem(0),
                rem(20px),
            ),
        ),
        size: (
            sizable: (
                rem(24px),
                rem(32px),
                rem(38px),
            ),
        ),
        default-size: 2,
    )
);

/// @type {Map}
/// @prop {Color} disabled-background [transparent] - The disabled background color of a flat button.
/// @requires {Map} $material-flat-button
/// @requires {Map} $light-flat-button
/// @requires {Map} $fluent-base-button
$fluent-flat-button: extend(
    $light-flat-button,
    $fluent-base-button,
    (
        disabled-background: transparent,
    )
);

/// @type {Map}
/// @prop {Map} border-color [color: ('gray', 600)] - The border color of an outlined button.
/// @prop {Map} hover-border-color [color: ('gray', 600)] - The hover border color of an outlined button.
/// @prop {Map} focus-border-color [color: ('gray', 600)] - The focus border color of the button.
/// @prop {Map} active-border-color [color: ('gray', 600)] - The active border color of an outlined button.
/// @prop {Map} disabled-border-color [color: ('gray', 300)] - The disabled focused border color of an outlined button.
/// @requires {Map} $light-outlined-button
/// @requires {Map} $fluent-base-button
$fluent-outlined-button: extend(
    $light-outlined-button,
    $fluent-base-button,
    (
        border-color: (
            color: (
                'gray',
                600,
            ),
        ),
        hover-border-color: (
            color: (
                'gray',
                600,
            ),
        ),
        focus-border-color: (
            color: (
                'gray',
                600,
            ),
        ),
        active-border-color: (
            color: (
                'gray',
                600,
            ),
        ),
        disabled-border-color: (
            color: (
                'gray',
                300,
            ),
        ),
        border-radius: (
            border-radius: (
                rem(2px),
                rem(0),
                rem(20px),
            ),
        ),
    )
);

/// @type {Map}
/// @prop {Map} focus-visible-border-color [contrast-color: ('primary', 900)] - The focus-visible border color of a contained button.
/// @prop {Map} background [color: ('primary', 500)] - The background color of an contained button.
/// @prop {Map} foreground [contrast-color: ('primary', 900)] - The idle text color of a contained button.
/// @prop {Map} icon-color [contrast-color: ('primary', 900)] - The idle icon color of the button.
/// @prop {Map} icon-color-hover [contrast-color: ('primary', 900)] - The hover icon color of the button.
/// @prop {Map} hover-background [color: ('primary', 600)] - The hover background of a contained button.
/// @prop {Map} hover-foreground [contrast-color: ('primary', 900)] - The hover text color of a contained button.
/// @prop {Map} focus-background [color: ('primary', 800)] - The background color of a contained button when focused with click and keyboard.
/// @prop {Map} focus-foreground [contrast-color: ('primary', 900)] - The text color of a contained button when focused with click and keyboard.
/// @prop {Map} focus-hover-background [color: ('primary', 600)] - The background color on focus hovered state of the button.
/// @prop {Map} focus-hover-foreground [contrast-color: ('primary', 900)] - The text color on focus hovered state of the button.
/// @prop {Map} focus-visible-background [color: ('primary', 500)] - The focus-visible background color of a contained button.
/// @prop {Map} focus-visible-foreground [contrast-color: ('primary', 900)] - The focus-visible text color of a contained button.
/// @prop {Map} active-background [color: ('primary', 800)] - The active background color of a contained button.
/// @prop {Map} active-foreground [contrast-color: ('primary', 900)] - The active text color of a contained button.
/// @prop {Number} resting-elevation [0] - The elevation level, between 0-24, to be used for the resting state.
/// @prop {Number} hover-elevation [0] - The elevation level, between 0-24, to be used for the hover state.
/// @prop {Number} focus-elevation [0] - The elevation level, between 0-24, to be used for the focus state.
/// @prop {List} border-radius [(rem(2px), rem(0), rem(20px))] - The border radius used for contained button.
/// @requires {Map} $light-contained-button
/// @requires {Map} $fluent-base-button
$fluent-contained-button: extend(
    $light-contained-button,
    $fluent-base-button,
    (
        selector: '[igxButton="contained"], .igx-button--contained',
        focus-visible-border-color: (
            contrast-color: (
                'primary',
                900,
            ),
        ),
        background: (
            color: (
                'primary',
                500,
            ),
        ),
        foreground: (
            contrast-color: (
                'primary',
                900,
            ),
        ),
        icon-color: (
            contrast-color: (
                'primary',
                900,
            ),
        ),
        icon-color-hover: (
            contrast-color: (
                'primary',
                900,
            ),
        ),
        hover-background: (
            color: (
                'primary',
                600,
            ),
        ),
        hover-foreground: (
            contrast-color: (
                'primary',
                900,
            ),
        ),
        focus-background: (
            color: (
                'primary',
                800,
            ),
        ),
        focus-foreground: (
            contrast-color: (
                'primary',
                900,
            ),
        ),
        focus-hover-background: (
            color: (
                'primary',
                600,
            ),
        ),
        focus-hover-foreground: (
            contrast-color: (
                'primary',
                900,
            ),
        ),
        focus-visible-background: (
            color: (
                'primary',
                500,
            ),
        ),
        focus-visible-foreground: (
            contrast-color: (
                'primary',
                900,
            ),
        ),
        active-background: (
            color: (
                'primary',
                800,
            ),
        ),
        active-foreground: (
            contrast-color: (
                'primary',
                900,
            ),
        ),
        border-radius: (
            border-radius: (
                rem(2px),
                rem(0),
                rem(20px),
            ),
        ),
    )
);

/// @type {Map}
/// @prop {Number} resting-elevation [0] - The elevation level, between 0-24, to be used for the resting state.
/// @prop {Number} hover-elevation [0] - The elevation level, between 0-24, to be used for the hover state.
/// @prop {Number} focus-elevation [0] - The elevation level, between 0-24, to be used for the focus state.
/// @prop {List} border-radius [(rem(14px), rem(12px), rem(28px))] - The border radius used for fab button.
/// @prop {List} size [(rem(32px), rem(40px), rem(48px))] - The size used for the button.
/// @requires {Map} $fluent-base-button
/// @requires {Map} $fluent-contained-button
$fluent-fab-button: extend(
    $fluent-base-button,
    $fluent-contained-button,
    (
        selector: '[igxButton="fab"], .igx-button--fab',
        border-radius: (
            border-radius: (
                rem(14px),
                rem(12px),
                rem(28px),
            ),
        ),
        size: (
            sizable: (
                rem(32px),
                rem(40px),
                rem(48px),
            ),
        ),
    )
);

/// Generates a light fluent button schema.
/// @type {Map}
/// @requires {Map} $fluent-flat-button
/// @requires {Map} $fluent-outlined-button
/// @requires {Map} $fluent-contained-button
/// @requires {Map} $fluent-fab-button
$fluent-button: (
    flat: $fluent-flat-button,
    outlined: $fluent-outlined-button,
    contained: $fluent-contained-button,
    fab: $fluent-fab-button,
);

/// @type {Map}
/// @prop {Map} disabled-background [color: ('primary', 50)] - The disabled background color of the button.
/// @prop {Map} disabled-foreground [color: ('primary', 200)] - The disabled foreground color of the button.
/// @prop {Map} disabled-icon-color [color: ('primary', 200)] - The disabled icon color of the button.
/// @prop {Map} shadow-color [color: ('primary', 600, .5)] - The shadow color of the button.
/// @prop {List} size [(rem(31px), rem(38px), rem(48px))] - The size used for the button.
/// @prop {Number} default-size [2] - The default size used for the button component.
/// @requires {Map} $light-base-button
/// @requires {Map} $flat-elevation-button
$bootstrap-base-button: extend(
    $light-base-button,
    $flat-elevation-button,
    (
        disabled-background: (
            color: (
                'primary',
                50,
            ),
        ),
        disabled-foreground: (
            color: (
                'primary',
                200,
            ),
        ),
        disabled-icon-color: (
            color: (
                'primary',
                200,
            ),
        ),
        shadow-color: (
            color: (
                'primary',
                600,
                0.5,
            ),
        ),
        size: (
            sizable: (
                rem(32px),
                rem(38px),
                rem(48px),
            ),
        ),
        default-size: 2,
    )
);

/// @type {Map}
/// @prop {Map} foreground [color: ('primary', 500)] - The idle text color of a flat button.
/// @prop {Map} icon-color [color: ('primary', 500)] - The idle icon color of the button.
/// @prop {Color} hover-background [transparent] - The hover background color of a flat button.
/// @prop {Map} hover-foreground [color: ('primary', 600)] - The hover text color of a flat button.
/// @prop {Map} icon-color-hover [color: ('primary', 600)] - The hover icon color of the button.
/// @prop {Color} focus-background [transparent] - The background color of a flat button when focused with click and keyboard.
/// @prop {Map} focus-foreground [color: ('primary', 800)] - The text color of a flat button when focused with click and keyboard.
/// @prop {Color} focus-hover-background [transparent] - The background color on focus hovered state of the button.
/// @prop {Map} focus-hover-foreground [color: ('primary', 600)] - The text color on focus hovered state of the button.
/// @prop {Color} focus-visible-background [transparent] - The focus-visible background color of a flat button.
/// @prop {Map} focus-visible-foreground [color: ('primary', 500)] - The focus-visible text color of a flat button.
/// @prop {Color} active-background [transparent] - The active background color of a flat button.
/// @prop {Map} active-foreground [color: ('primary', 800)] - The active background color of a flat button.
/// @prop {Color} disabled-background [transparent] - The disabled background color of a flat button.
/// @requires {Map} $light-flat-button
/// @requires {Map} $bootstrap-base-button
$bootstrap-flat-button: extend(
    $light-flat-button,
    $bootstrap-base-button,
    (
        foreground: (
            color: (
                'primary',
                500,
            ),
        ),
        icon-color: (
            color: (
                'primary',
                500,
            ),
        ),
        hover-background: transparent,
        hover-foreground: (
            color: (
                'primary',
                600,
            ),
        ),
        icon-color-hover: (
            color: (
                'primary',
                600,
            ),
        ),
        focus-background: transparent,
        focus-foreground: (
            color: (
                'primary',
                800,
            ),
        ),
        focus-hover-background: transparent,
        focus-hover-foreground: (
            color: (
                'primary',
                600,
            ),
        ),
        focus-visible-background: transparent,
        focus-visible-foreground: (
            color: (
                'primary',
                500,
            ),
        ),
        active-background: transparent,
        active-foreground: (
            color: (
                'primary',
                800,
            ),
        ),
        disabled-background: transparent,
    )
);

/// @type {Map}
/// @prop {Map} foreground [color: ('primary', 500)] - The idle text color of an outlined button.
/// @prop {Map} icon-color [color: ('primary', 500)] - The idle icon color of an outlined button.
/// @prop {Map} border-color [color: ('primary', 500)] - The border color of an outlined button.
/// @prop {Map} hover-background [color: ('primary', 600)] - The hover background color of an outlined button.
/// @prop {Map} hover-foreground [contrast-color: ('primary', 600)] - The hover text color of an outlined button.
/// @prop {Map} icon-color-hover [contrast-color: ('primary', 600)] - The hover icon color of an outlined button.
/// @prop {Map} hover-border-color [color: ('primary', 600)] - The hover border color of an outlined button.
/// @prop {Map} focus-background [color: ('primary', 800)] - The background color of an outlined button when focused with click and keyboard.
/// @prop {Map} focus-foreground [contrast-color: ('primary', 800)] - The text color of an outlined button when focused with click and keyboard.
/// @prop {Map} focus-border-color [color: ('primary', 600)] - The focus border color of an outlined button.
/// @prop {Map} focus-hover-background [color: ('primary', 600)] - The background color on focus hovered state of the button.
/// @prop {Map} focus-hover-foreground [contrast-color: ('primary', 600)] - The text color on focus hovered state of the button.
/// @prop {Map} focus-visible-background [color: ('primary', 500)] - The focus-visible background color of an outlined button.
/// @prop {Map} focus-visible-foreground [contrast-color: ('primary', 600)] - The focus-visible text color of an outlined button.
/// @prop {Map} focus-visible-border-color [color: ('primary', 500)] - The focus-visible border color of an outlined button.
/// @prop {Map} active-background [color: ('primary', 800)] - The active background color of an outlined button.
/// @prop {Map} active-foreground [contrast-color: ('primary', 800)] - The active text color of an outlined button.
/// @prop {Map} active-border-color [color: ('primary', 800)] - The active border color of an outlined button.
/// @prop {Map} disabled-border-color [color: ('primary', 50), lighten: 35%] - The disabled focused border color of an outlined button.
/// @prop {Color} disabled-background [transparent] - The disabled background color of an outlined button.
/// @requires {Map} $light-outlined-button
/// @requires {Map} $bootstrap-base-button
$bootstrap-outlined-button: extend(
    $light-outlined-button,
    $bootstrap-base-button,
    (
        foreground: (
            color: (
                'primary',
                500,
            ),
        ),
        icon-color: (
            color: (
                'primary',
                500,
            ),
        ),
        border-color: (
            color: (
                'primary',
                500,
            ),
        ),
        hover-background: (
            color: (
                'primary',
                600,
            ),
        ),
        hover-foreground: (
            contrast-color: (
                'primary',
                600,
            ),
        ),
        icon-color-hover: (
            contrast-color: (
                'primary',
                600,
            ),
        ),
        hover-border-color: (
            color: (
                'primary',
                600,
            ),
        ),
        focus-background: (
            color: (
                'primary',
                800,
            ),
        ),
        focus-foreground: (
            contrast-color: (
                'primary',
                800,
            ),
        ),
        focus-border-color: (
            color: (
                'primary',
                600,
            ),
        ),
        focus-hover-background: (
            color: (
                'primary',
                600,
            ),
        ),
        focus-hover-foreground: (
            contrast-color: (
                'primary',
                600,
            ),
        ),
        focus-visible-background: (
            color: (
                'primary',
                500,
            ),
        ),
        focus-visible-foreground: (
            contrast-color: (
                'primary',
                600,
            ),
        ),
        focus-visible-border-color: (
            color: (
                'primary',
                500,
            ),
        ),
        active-background: (
            color: (
                'primary',
                800,
            ),
        ),
        active-foreground: (
            contrast-color: (
                'primary',
                800,
            ),
        ),
        active-border-color: (
            color: (
                'primary',
                800,
            ),
        ),
        disabled-border-color: (
            color: (
                'primary',
                50,
            ),
        ),
        disabled-background: transparent,
    )
);

/// @type {Map}
/// @prop {Map} background [color: ('primary', 500)] - The background color of a contained button.
/// @prop {Map} foreground [contrast-color: ('primary', 600)] - The idle text color of a contained button.
/// @prop {Map} icon-color [contrast-color: ('primary', 600)] - The idle icon color of the button.
/// @prop {Map} icon-color-hover [contrast-color: ('primary', 600)] - The hover icon color of the button.
/// @prop {Map} hover-background [color: ('primary', 600)] - The hover background color of an contained button.
/// @prop {Map} hover-foreground [contrast-color: ('primary', 600)] - The hover text color of an contained button.
/// @prop {Map} focus-background [color: ('primary', 800)] - The background color of a contained button when focused with click and keyboard.
/// @prop {Map} focus-hover-background [color: ('primary', 600)] - The background color on focus hovered state of the button.
/// @prop {Map} focus-hover-foreground [contrast-color: ('primary', 600)] - The text color on focus hovered state of the button.
/// @prop {Map} focus-visible-foreground [contrast-color: ('primary', 600)] - The focus-visible text color of an contained button.
/// @prop {Map} focus-visible-background [color: ('primary', 600)] - The focus-visible background color of an contained button.
/// @prop {Map} focus-foreground [contrast-color: ('primary', 800)] - The text color of a contained button when focused with click and keyboard.
/// @prop {Map} active-background [color: ('primary', 800)] - The active background color of an contained button.
/// @prop {Map} active-foreground [contrast-color: ('primary', 800)] - The active text color of an contained button.
/// @requires {Map} $light-contained-button
/// @requires {Map} $bootstrap-base-button
$bootstrap-contained-button: extend(
    $light-contained-button,
    $bootstrap-base-button,
    (
        background: (
            color: (
                'primary',
                500,
            ),
        ),
        foreground: (
            contrast-color: (
                'primary',
                600,
            ),
        ),
        icon-color: (
            contrast-color: (
                'primary',
                600,
            ),
        ),
        icon-color-hover: (
            contrast-color: (
                'primary',
                600,
            ),
        ),
        hover-background: (
            color: (
                'primary',
                600,
            ),
        ),
        hover-foreground: (
            contrast-color: (
                'primary',
                600,
            ),
        ),
        focus-background: (
            color: (
                'primary',
                800,
            ),
        ),
        focus-hover-background: (
            color: (
                'primary',
                600,
            ),
        ),
        focus-hover-foreground: (
            contrast-color: (
                'primary',
                600,
            ),
        ),
        focus-foreground: (
            contrast-color: (
                'primary',
                800,
            ),
        ),
        focus-visible-background: (
            color: (
                'primary',
                500,
            ),
        ),
        focus-visible-foreground: (
            contrast-color: (
                'primary',
                600,
            ),
        ),
        active-background: (
            color: (
                'primary',
                800,
            ),
        ),
        active-foreground: (
            contrast-color: (
                'primary',
                800,
            ),
        ),
    )
);

/// @type {Map}
/// @prop {List} border-radius [(rem(15px), rem(12px), rem(28px))] - The border radius used for outlined button.
/// @requires {Map} $light-fab-button
/// @requires {Map} $bootstrap-base-button
/// @requires {Map} $bootstrap-contained-button
$bootstrap-fab-button: extend(
    $light-fab-button,
    $bootstrap-base-button,
    $bootstrap-contained-button,
    (
        selector: '[igxButton="fab"], .igx-button--fab',
        border-radius: (
            border-radius: (
                rem(15px),
                rem(12px),
                rem(28px),
            ),
        ),
    )
);

/// Generates a light bootstrap button schema.
/// @type {Map}
/// @requires {Map} $bootstrap-flat-button
/// @requires {Map} $bootstrap-outlined-button
/// @requires {Map} $bootstrap-contained-button
/// @requires {Map} $bootstrap-fab-button
$bootstrap-button: (
    flat: $bootstrap-flat-button,
    outlined: $bootstrap-outlined-button,
    contained: $bootstrap-contained-button,
    fab: $bootstrap-fab-button,
);

/// @type {Map}
/// @prop {Map} foreground [color: ('gray', 700)] - The idle text color of the button.
/// @prop {Map} icon-color [color: ('gray', 600)] - The idle icon color of the button.
/// @prop {Map} icon-color-hover [color: ('gray', 700)] - The hover icon color of the button.
/// @prop {Map} hover-background [color: ('gray', 900, 0.5)] - The hover background color of the button.
/// @prop {Map} hover-foreground [color: ('gray', 900)] - The hover text color of the button.
/// @prop {Map} focus-background [color: ('gray', 900, 0.5)] - The background color of the button when focused with click and keyboard.
/// @prop {Map} focus-foreground [color: ('gray', 900)] - The text color of the button when focused with click and keyboard.
/// @prop {Map} focus-hover-background [color: ('gray', 900, 0.5)] - The background color on focus hovered state of the button.
/// @prop {Map} focus-hover-foreground [color: ('gray', 900)] - The text color on focus hovered state of the button.
/// @prop {Map} active-background [color: ('gray', 900, 0.5)] - The active background color of the button.
/// @prop {Map} active-foreground [color: ('gray', 900)] - The active text color of the button.
/// @prop {Color} focus-visible-background [transparent] - The focus-visible background color of the button.
/// @prop {Map} focus-visible-foreground [color: ('gray', 700)] - The focus-visible text color of the button.
/// @prop {Map} shadow-color [color: ('gray', 900, .15)] - The shadow color of the button.
/// @prop {Color} disabled-background [transparent] - The disabled background color of the button.
/// @prop {Map} disabled-foreground [color: ('gray', 900, .20)] - The disabled foreground color of the button.
/// @prop {Map} disabled-icon-color [color: ('gray', 900, .20)] - The disabled icon color of the button.
/// @prop {List} border-radius [(rem(40px), rem(0), rem(40px))] - The border radius used for the button.
/// @prop {List} size [(rem(24px), rem(28px), rem(32px))] - The size used for the button.
/// @prop {Number} default-size [2] - The default size used for the button component.
/// @requires {Map} $light-base-button
/// @requires {Map} $flat-elevation-button
$indigo-base-button: extend(
    $light-base-button,
    $flat-elevation-button,
    (
        foreground: (
            color: (
                'gray',
                700,
            ),
        ),
        icon-color: (
            color: (
                'gray',
                600,
            ),
        ),
        icon-color-hover: (
            color: (
                'gray',
                700,
            ),
        ),
        hover-background: (
            color: (
                'gray',
                900,
                0.05,
            ),
        ),
        hover-foreground: (
            color: (
                'gray',
                900,
            ),
        ),
        focus-background: (
            color: (
                'gray',
                900,
                0.05,
            ),
        ),
        focus-foreground: (
            color: (
                'gray',
                900,
            ),
        ),
        focus-hover-background: (
            color: (
                'gray',
                900,
                0.05,
            ),
        ),
        focus-hover-foreground: (
            color: (
                'gray',
                900,
            ),
        ),
        active-background: (
            color: (
                'gray',
                900,
                0.05,
            ),
        ),
        active-foreground: (
            color: (
                'gray',
                900,
            ),
        ),
        focus-visible-background: transparent,
        focus-visible-foreground: (
            color: (
                'gray',
                700,
            ),
        ),
        shadow-color: (
            color: (
                'gray',
                900,
                0.15,
            ),
        ),
        disabled-background: transparent,
        disabled-foreground: (
            color: (
                'gray',
                900,
                0.2,
            ),
        ),
        disabled-icon-color: (
            color: (
                'gray',
                900,
                0.2,
            ),
        ),
        border-radius: (
            border-radius: (
                rem(40px),
                rem(0),
                rem(40px),
            ),
        ),
        size: (
            sizable: (
                rem(24px),
                rem(28px),
                rem(32px),
            ),
        ),
        default-size: 2,
    )
);

/// @type {Map}
/// @requires {Map} $light-flat-button
/// @requires {Map} $indigo-base-button
$indigo-flat-button: extend($light-flat-button, $indigo-base-button);

/// @type {Map}
/// @prop {Map} border-color [color: ('gray', 500)] - The border color of an outlined button.
/// @prop {Map} hover-border-color [color: ('gray', 600)] - The hover border color of a outlined button.
/// @prop {Map} focus-border-color [color: ('gray', 500)] - The focus border color of a outlined button.
/// @prop {Map} focus-visible-border-color [color: ('gray', 500)] - The focus-visible border color of a outlined button.
/// @prop {Map} active-border-color [color: ('gray', 900, 0.15)] - The active border color of a outlined button.
/// @prop {Map} disabled-border-color [color: ('gray', 900, 0.15)] - The disabled focused border color of an outlined button.
/// @requires {Map} $light-outlined-button
/// @requires {Map} $indigo-base-button
$indigo-outlined-button: extend(
    $light-outlined-button,
    $indigo-base-button,
    (
        border-color: (
            color: (
                'gray',
                500,
            ),
        ),
        hover-border-color: (
            color: (
                'gray',
                600,
            ),
        ),
        focus-border-color: (
            color: (
                'gray',
                500,
            ),
        ),
        focus-visible-border-color: (
            color: (
                'gray',
                500,
            ),
        ),
        active-border-color: (
            color: (
                'gray',
                600,
            ),
        ),
        disabled-border-color: (
            color: (
                'gray',
                900,
                0.15,
            ),
        ),
    )
);

/// @type {Map}
/// @prop {Map} background [color: ('primary', 500)] - The background color of a flat button.
/// @prop {Map} foreground [contrast-color: ('primary', 500)] - The idle text color of a contained button.
/// @prop {Map} icon-color [contrast-color: ('primary', 500, 0.8)] - The idle icon color of the button.
/// @prop {Map} icon-color-hover [contrast-color: ('primary', 500)] - The hover icon color of the button.
/// @prop {Map} hover-background [color: ('primary', 400)] - The hover background color of a contained button.
/// @prop {Map} hover-foreground [contrast-color: ('primary', 500)] - The hover text color of a contained button.
/// @prop {Map} focus-background [color: ('primary', 400)] - The background color of a contained button when focused with click and keyboard.
/// @prop {Map} focus-foreground [contrast-color: ('primary', 500)] - The text color of a contained button when focused with click and keyboard.
/// @prop {Map} focus-hover-background [color: ('primary', 400)] - The background color on focus hovered state of the button.
/// @prop {Map} focus-hover-foreground [contrast-color: ('primary', 500)] - The text color on focus hovered state of the button.
/// @prop {Map} focus-visible-background [color: ('primary', 500)] - The focus-visible background color of a contained button.
/// @prop {Map} focus-visible-foreground [contrast-color: ('primary', 500)] - The focus-visible text color of a contained button.
/// @prop {Map} active-background [color: ('primary', 400)] - The active background color of a contained button.
/// @prop {Map} active-foreground [contrast-color: ('primary', 500)] - The active text color of a flat button.
/// @prop {Map} shadow-color [color: ('primary', 400, 0.5)] - The shadow color of a contained button.
/// @prop {Map} disabled-background [color: ('primary', 400, 0.50)] - The disabled background color of a flat button.
/// @prop {Map} disabled-foreground [contrast-color: ('primary', 500, 0.4)] - The disabled foreground color of the button.
/// @prop {Map} disabled-icon-color [contrast-color: ('primary', 500, 0.3)] - The disabled icon color of the button.
/// @requires {Map} $light-contained-button
/// @requires {Map} $indigo-base-button
$indigo-contained-button: extend(
    $light-contained-button,
    $indigo-base-button,
    (
        background: (
            color: (
                'primary',
                500,
            ),
        ),
        foreground: (
            contrast-color: (
                'primary',
                500,
            ),
        ),
        icon-color: (
            contrast-color: (
                'primary',
                500,
                0.8,
            ),
        ),
        icon-color-hover: (
            contrast-color: (
                'primary',
                500,
            ),
        ),
        hover-background: (
            color: (
                'primary',
                400,
            ),
        ),
        hover-foreground: (
            contrast-color: (
                'primary',
                500,
            ),
        ),
        focus-background: (
            color: (
                'primary',
                400,
            ),
        ),
        focus-foreground: (
            contrast-color: (
                'primary',
                500,
            ),
        ),
        focus-hover-background: (
            color: (
                'primary',
                400,
            ),
        ),
        focus-hover-foreground: (
            contrast-color: (
                'primary',
                500,
            ),
        ),
        focus-visible-background: (
            color: (
                'primary',
                500,
            ),
        ),
        focus-visible-foreground: (
            contrast-color: (
                'primary',
                500,
            ),
        ),
        active-background: (
            color: (
                'primary',
                400,
            ),
        ),
        active-foreground: (
            contrast-color: (
                'primary',
                500,
            ),
        ),
        shadow-color: (
            color: (
                'primary',
                400,
                0.5,
            ),
        ),
        disabled-background: (
            color: (
                'primary',
                400,
                0.5,
            ),
        ),
        disabled-foreground: (
            contrast-color: (
                'primary',
                500,
                0.4,
            ),
        ),
        disabled-icon-color: (
            contrast-color: (
                'primary',
                500,
                0.3,
            ),
        ),
    )
);

/// @type {Map}
/// @prop {List} border-radius [(rem(40px), rem(12px), rem(40px))] - The border radius used for fab button.
/// @prop {List} size [(rem(28px), rem(32px), rem(36px))] - The size used for the fab button.
/// @requires {Map} $light-fab-button
/// @requires {Map} $indigo-base-button
/// @requires {Map} $indigo-contained-button
$indigo-fab-button: extend(
    $light-fab-button,
    $indigo-base-button,
    $indigo-contained-button,
    (
        selector: '[igxButton="fab"], .igx-button--fab',
        border-radius: (
            border-radius: (
                rem(40px),
                rem(12px),
                rem(40px),
            ),
        ),
        size: (
            sizable: (
                rem(28px),
                rem(32px),
                rem(36px),
            ),
        ),
    )
);

/// Generates a light indigo button schema.
/// @type {Map}
/// @requires {Map} $indigo-flat-button
/// @requires {Map} $indigo-outlined-button
/// @requires {Map} $indigo-contained-button
/// @requires {Map} $indigo-fab-button
$indigo-button: (
    flat: $indigo-flat-button,
    outlined: $indigo-outlined-button,
    contained: $indigo-contained-button,
    fab: $indigo-fab-button,
);
