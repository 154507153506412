/* stylelint-disable max-line-length */
@use '../light/category-chart' as *;

////
/// @package theming
/// @group schemas
/// @access public
/// @author <a href="https://github.com/SisIvanova" target="_blank">Silvia Ivanova</a>
////

/// Generates a dark category-chart schema based on a mix of $material-category-chart and $base-dark-category-chart
/// @type {Map}
/// @requires $material-category-chart
$dark-material-category-chart: $material-category-chart;

/// Generates a dark fluent category-chart schema based on a mix of $fluent-category-chart and $base-dark-category-chart
/// @type {Map}
/// @requires $fluent-category-chart
$dark-fluent-category-chart: $fluent-category-chart;

/// Generates a bootstrap category-chart schema based on a mix of $bootstrap-category-chart and $base-dark-category-chart
/// @type {Map}
/// @requires $bootstrap-category-chart
$dark-bootstrap-category-chart: $bootstrap-category-chart;

/// Generates an indigo category-chart schema based on a mix of $indigo-category-chart and $base-dark-category-chart
/// @type {Map}
/// @requires $indigo-category-chart
$dark-indigo-category-chart: $indigo-category-chart;
