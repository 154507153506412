/* stylelint-disable max-line-length */
////
/// @package theming
/// @group schemas
/// @access public
/// @author <a href="https://github.com/desig9stein" target="_blank">Marin Popov</a>
////

/// Generates a material funnel chart schema.
/// @type {Map}
/// @property {List} brushes [series] - Defines the palette from which automatically assigned series colors are selected.
/// @property {List} outlines [series] - Defines the palette from which automatically assigned series outline colors are selected.
/// @property {String} outer-label-alignment [null] - Sets which side of the chart the outer labels should appear.
/// @property {Color} outer-label-text-color [null] - Sets the Color used for the outer labels.
/// @property {String} outer-label-text-style [null] - Sets the text style for outer labels.
/// @property {Color} outer-label-visibility [null] - Sets whether the outer labels are visible.
/// @property {Number} outline-thickness [null] - Sets the thickness of outline around slices.
/// @property {Color} text-color [null] - Sets the Color used for the inner labels.
/// @property {String} text-style [null] - Sets the text style for inner labels.
$material-funnel-chart: (
    'brushes': series,
    'outlines': series,
    'outer-label-alignment': null,
    'outer-label-text-color': null,
    'outer-label-text-style': null,
    'outer-label-visibility': null,
    'outline-thickness': null,
    'text-color': null,
    'text-style': null,
);

/// Generates a fluent funnel-chart schema.
/// @type {Map}
/// @requires {function} extend
/// @requires {Map} $material-funnel-chart
$fluent-funnel-chart: $material-funnel-chart;

/// Generates a bootstrap funnel-chart schema.
/// @type {Map}
/// @requires {function} extend
/// @requires {Map} $material-funnel-chart
$bootstrap-funnel-chart: $material-funnel-chart;

/// Generates an indigo funnel-chart schema.
/// @type {Map}
/// @requires {function} extend
/// @requires {Map} $material-funnel-chart
$indigo-funnel-chart: $material-funnel-chart;
